<template>
	<main>
		<OrderStep :step="4"/>
		<div class="container">
			<Confirmation :mainText="confirmationMainText"
                    :subText="confirmationSubText" />
		</div>
	</main>
</template>

<script>
import OrderStep from "@/components/OrderStep";
import Confirmation from "@/components/Confirmation";

// TODO User's variables in texts below
export default {
  name: "Purchase",
  components:{OrderStep, Confirmation},
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    }
  },
  data () {
    return {
	    confirmationMainText: `
			Merci,
			<br/> Votre commande ${this.orderId ? this.orderId : ''}<br/> est bien enregistrée !
		`,
	    confirmationSubText: `
			Un e-mail de confirmation vous a été envoyé.
			<br/>Des questions ?
			<br/>Contactez-nous au <a href="tel:0299894816" target="_blank" style="color: #ae7935; text-decoration: none;">02 99 89 48 16</a>
		`,
    }
  },
  mounted() {
    this.$store.dispatch('cart/clearCart')
  }
}
</script>

<style lang="scss" scoped>
.container {
	@include flex(flex-start, flex-start, column, $gap: 20px);
	position: relative;
	width: 90vw;
	margin: 0 auto;
	@media screen and (max-width: 400px) {
		width: 90%;
	}
	@media screen and (min-width: 992px) {
		width: 90vw;
	}
	@media screen and (min-width: 1200px) {
		width: 80vw;
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;
		justify-content: space-between;
	}
}

</style>