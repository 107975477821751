<template>
  <transition name="modal">
    <div class="modal-mask" @click.self="closeModal">
      <div class="modal">
        <div class="modal__header">
          <button aria-label="close-modal" type="button" @click="closeModal" class="button close-modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
              <path
                  d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
        </div>
        <div class="modal__body">
          <h3 v-if="$props.partner===true">Ajouter une adresse </h3>
          <h3 v-else>Modifier mon adresse </h3>
          <form method="post" id="contact_form" @submit.prevent="saveContact">
            <h4>Cette adresse est pour :</h4>
            <div class="form__row">
              <div class="form__row__item">
                <input
                    type="radio"
                    class="input"
                    id="is_not_company"
                    name="is_not_company"
                    v-model="respartner.is_company"
                    @change="isCompany(respartner.is_company)"
                    :value=false
                    checked
                />
                <label for="is_not_company">Un Particulier</label>
              </div>
              <div class="form__row__item">
                <input
                    type="radio"
                    class="input"
                    id="is_company"
                    name="is_company"
                    v-model="respartner.is_company"
                    @change="isCompany(respartner.is_company)"
                    :value=true
                />
                <label for="is_company">Une Société</label>
              </div>
            </div>
            <template v-if="respartner.is_company">
              <h4>La Société</h4>
              <div class="form__row">
                <div class="form__row__item">
                  <input
                      type="text"
                      id="company"
                      name="company"
                      class="input"
                      v-model="respartner.name"
                      placeholder="Nom de la Société"
                      required
                  />
                  <label for="company">Société</label>

                </div>
              </div>
              <div class="form__row">
                <div class="form__row__item">
                  <input
                      type="text"
                      id="siret"
                      name="siret"
                      v-model="respartner.siret"
                      class="input"
                      placeholder="SIRET"
                      required
                  />
                  <label for="siret">N°SIRET</label>
                </div>
              </div>
            </template>

            <h4>Votre Adresse</h4>
            <div class="form__row">
              <div class="form__row__item">
                <AddressAutocomplete id="street"
                                     :placeholder="respartner.street ? (respartner.street +' '+ respartner.zip +' '+ respartner.city) : 'rechercher votre adresse'"
                                     classname="input"
                                     :set-location="false"
                                     v-on:placechanged="getAddressData"
                ></AddressAutocomplete>
                <label for="street">Adresse</label>
              </div>
            </div>
            <div class="form__row">
              <div class="form__row__item">
                <input
                    type="text"
                    id="street2"
                    name="street2"
                    v-model="respartner.street2"
                    autocomplete="off"
                    class="input"
                    placeholder="Complément d'adresse"
                />
                <label for="street2">Compl. d'adresse</label>
              </div>
            </div>
            <h4>Vos Coordonnées</h4>
            <div class="form__row">
              <div class="form__row__item">
                <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    v-model="respartner.first_name"
                    autocomplete="off"
                    class="input"
                    required
                    placeholder="Prénom"
                />
                <label for="first_name">Prénom</label>
              </div>
              <div class="form__row__item">
                <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    v-model="respartner.last_name"
                    autocomplete="off"
                    class="input"
                    required
                    placeholder="Nom"
                />
                <label for="last_name">Nom</label>
              </div>
            </div>
            <div class="form__row">
              <div class="form__row__item">
                <input
                    type="email"
                    id="email"
                    name="email"
                    v-model="respartner.email"
                    autocomplete="off"
                    class="input"
                    required
                    placeholder="E-mail"
                />
                <label for="email">Adresse e-mail</label>
              </div>
            </div>
            <div class="form__row">
              <div class="form__row__item">
                <input
                    type="text"
                    pattern="((\+|00)?[1-9]{2}|0)[1-9]( ?[0-9]){8}"
                    id="phone"
                    name="phone"
                    v-model="respartner.phone"
                    autocomplete="off"
                    class="input"
                    required
                    placeholder="Numéro de téléphone"
                />
                <label for="phone">Téléphone</label>
              </div>
            </div>

            <h4>Souhaitez-vous faire de cette adresse :</h4>
            <h5>votre adresse de livraison par défaut ?</h5>
            <div class="form__row radio">
              <div class="form__row__item">
                <label>oui
                  <input
                      type="radio"
                      class="input"
                      id="defaultDeliveryAddressTrue"
                      name="defaultDeliveryAddress"
                      v-model="defaultDeliveryAddress"
                      :value=true
                      checked
                  />
                </label>
              </div>
              <div class="form__row__item">
                <label>non
                  <input
                      type="radio"
                      class="input"
                      id="defaultDeliveryAddressFalse"
                      name="defaultDeliveryAddress"
                      v-model="defaultDeliveryAddress"
                      :value=false
                  />
                </label>
              </div>
            </div>

            <h5>votre adresse de facturation par défaut ?</h5>
            <div class="form__row radio">
              <div class="form__row__item">
                <label>oui
                  <input
                      type="radio"
                      class="input"
                      id="defaultInvoiceAddressTrue"
                      name="defaultInvoiceAddress"
                      v-model="defaultInvoiceAddress"
                      :value=true
                      checked
                  />
                </label>
              </div>
              <div class="form__row__item">
                <label>non
                  <input
                      type="radio"
                      class="input"
                      id="defaultInvoiceAddressFalse"
                      name="defaultInvoiceAddress"
                      v-model="defaultInvoiceAddress"
                      :value=false
                  />
                </label>
              </div>
            </div>

            <button aria-label="validate" type="submit" class="button full" >Valider</button>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {getAPI} from "@/services/axios-api";
import {i18n} from "@/main";
import AddressAutocomplete from "@/components/AddressAutocomplete";

export default {
  name: "PartnerModal",
  components: {AddressAutocomplete},

  props: {
    partner: {
      type: [Object, Boolean],
    },
  },
  data() {
    return {
      defaultDeliveryAddress: true,
      defaultInvoiceAddress: true,
      errors: [],
      respartner: typeof (this.partner) === 'object' ? this.partner : {
        first_name: null,
        last_name: null,
        street: null,
        street2: null,
        zip: null,
        city: null,
        phone: null,
        is_company: false,
        siret: null,
        name: null,
        country: null,
        country_id: null,
        longitude: null,
        latitude: null,
        type: 'delivery',
      }
    }
  },
  computed: {
    ...mapState('auth', ['userId']),
    ...mapGetters('error', ['getErrorMessage']),
  },
  watch :{
    'respartner.last_name':{
      handler() {
        this.respartner.name = this.respartner.last_name.toUpperCase()+', '+this.respartner.first_name[0].toUpperCase() + this.respartner.first_name.slice(1);
        this.respartner.display_name = this.respartner.last_name.toUpperCase()+', '+this.respartner.first_name[0].toUpperCase() + this.respartner.first_name.slice(1);
      }
    },
    'respartner.first_name':{
      handler() {
        this.respartner.name = this.respartner.last_name.toUpperCase()+', '+this.respartner.first_name[0].toUpperCase() + this.respartner.first_name.slice(1);
        this.respartner.display_name = this.respartner.last_name.toUpperCase()+', '+this.respartner.first_name[0].toUpperCase() + this.respartner.first_name.slice(1);
      }
    },
  },
  methods: {
    getAddressData(addData) {
      this.respartner.street = addData.street;
      this.respartner.zip = addData.zip;
      this.respartner.city = addData.city;
      this.respartner.country = addData.country;
      this.respartner.longitude = addData.longitude;
      this.respartner.latitude = addData.latitude;

    },
    isCompany() {
      if (this.respartner.is_company) {
        document.getElementById('companyInputEdit').style.display = 'block';
      } else {
        document.getElementById('companyInputEdit').style.display = 'none';
      }
    },
    closeModal: function () {
      this.$store.dispatch('changeStateShowPartnerModal', false);
      document.querySelector('html').style.overflow = null
    },
    saveContact: async function () {
      if (!this.respartner.is_company) {
        this.respartner.last_name = this.respartner.last_name.toUpperCase()
        this.respartner.first_name = this.respartner.first_name[0].toUpperCase() + this.respartner.first_name.slice(1)
      } else {
        this.respartner.name = this.respartner.name.toUpperCase()
      }
      const formData = this.respartner;
      console.log(formData)
      const err = this.errors;
      if (err.length <= 0) {
        if(this.partner===true){
          await getAPI
              .post('/respartner/', {
                'data': formData, 'create': 'child'
              })
              .then(response => {
                if (this.defaultDeliveryAddress) {
                  getAPI.post('/account/profile/default/address/', {'delivery_address': response.data})
                }
                if (this.defaultInvoiceAddress) {
                  getAPI.post('/account/profile/default/address/', {'invoice_address': response.data})
                }
                this.$parent.getDeliveryAddress();
              })
              .catch(err => {
                let error = {'message': err.message, 'details': err.response.data.error};
                this.$store.dispatch('error/setError', error)
                document.getElementById('creationKO').style.display = 'block';
              })
              .finally(() => {
                if (this.getErrorMessage === null) {
                  this.$toast.open({
                    message: i18n.t('add_partner_success'),
                    type: 'success',
                  })
                }
              })
        }else{
          const id = this.respartner.id ? this.respartner.id : this.$attrs.id ;
          await getAPI
              .patch('/respartner/' + id + '/', {
                'data': formData, 'update': 'partner'
              })
              .then(response => {
                if (response.status === 200) {
                  this.$toast.open({
                    message: response.data.success,
                    type: 'success',
                  });
                  this.respartner = response.data.update_partner
                } else {
                  this.$toast.open({
                    message: response.data.error,
                    type: 'error',
                  });
                }
              })
              .catch(error => console.log(error.data))

          const formUser = {'first_name': formData.first_name, 'last_name': formData.last_name}
          await getAPI.patch('/account/profile/' + this.userId +'/', formUser)
          if (this.defaultDeliveryAddress) {
            await getAPI.post('/account/profile/default/address/', {'delivery_address': this.respartner.id})
          }
          if (this.defaultInvoiceAddress) {
            await getAPI.post('/account/profile/default/address/', {'invoice_address': this.respartner.id})
          }
        }
      }
      this.closeModal();
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  @include flex(center, flex-start);
  @include position(0, 0, $position: fixed);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s ease;
  overflow: auto;
  padding: 5vh 0;
  scrollbar-color: $t $t;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

}

.modal {
  @include flex(center, center, column, $gap: 10px);
  background-color: $White;
  border-radius: 15px;
  width: 700px;
  max-width: 90%;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 30%);
  padding: 20px;
  pointer-events: all;
  position: relative;
  z-index: 998;

  &__header {
    @include flex(flex-start, flex-start, $gap: 50px);
    width: 100%;

    button.close-modal {
      padding: 5px;
      aspect-ratio: 1;
      margin-left: auto;
    }
  }

  &__body {
    @include flex(flex-end, center, column, $gap: 10px);
    width: 100%;

    h2 {
      font-size: 18px;
      font-weight: 600;
      width: 100%;
      margin-bottom: 10px;
    }

    form {
      @include flex(flex-start, flex-start, column, $gap: 10px);
      width: 100%;

      h4 {
        margin-top: 30px;
      }

      h5 {
        margin-bottom: -5px;
        font-size: 13px;
        font-weight: 700;
      }

      .form__row {
        @include flex(flex-start, flex-start, $gap: 10px);
        width: 100%;

        @media screen and (max-width: 575.99px) {
          flex-direction: column;
        }

        &.radio {
          width: fit-content;
          gap: 20px;
          margin-top: -2.5px;

          label {
            @include flex(flex-start, center, $gap: 5px);
          }
        }

        &__item {
          @include flex(flex-start, flex-start, column-reverse, $gap: 3px);
          width: 100%;

          .input[required] ~ label::after {
            content: '*';
            color: #e33131;
            font-weight: bold;
            margin-left: 2px;
          }

          label {
            color: $Black;
            font-weight: 600;
            font-size: 14px;
            position: relative;
          }

          .input:not([type="radio"]) {
            background-color: #F9F9F9;
            color: $Black;
            padding: 10px 20px;
            width: 100%;

            &::placeholder {
              color: #BDBDBD;
              text-transform: uppercase;
            }
          }
        }
      }

    }

    .button {
      width: 100%;
      padding: 5px 10px;
      margin: 0 auto;
      @media screen and (min-width: 568px) {
        width: auto;
      }
    }
  }
}

</style>