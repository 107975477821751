<template>
	<transition name="modal">
		<div class="modal-mask" @click.self="closeModal">
			<div class="modal">
				<div class="modal__header">
					<button aria-label="close" type="button" @click="closeModal" class="button close-modal">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
						     style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
							<path
								d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
						</svg>
					</button>
				</div>
				<div class="modal__body">
					<h3>Sélectionner l'adresse de livraison souhaitée</h3>
					<ul>
						<li v-for="partner in listRespartner">
							<label>
								<input
									class="input"
									v-model="$parent.deliveryAdSelected"
									:id="'delAdd' + partner.id"
									:name="'delAdd' + partner.id"
									type="radio"
									:value="partner.id"
									@change="selectDeliveryPartner(partner.id)"
								>
								<span class="item-address" style="display: block;">
								<span> {{ partner.name }}</span>
								<span> {{ partner.street }}, {{ partner.street2 }}</span>
								<span> {{ partner.zip }} - {{ partner.city }}</span>
							</span>
							</label>
						</li>
					</ul>
					<button aria-label="close" type="button" @click="closeModal" class="button">Fermer</button>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "SelectDeliveryModal",
	data() {
		return {
			listRespartner: null,
		}
	},
	mounted() {
		this.getListRespartner();
	},
	methods: {
		getListRespartner() {
			this.listRespartner = this.$parent.partner_address
		},
		selectDeliveryPartner(partnerId) {
			this.$parent.deliveryDefault = this.listRespartner.find(e => e.id === partnerId);
      if(!this.$parent.deliveryDefault.street) {
        this.$parent.deliveryEditing = true;
      }else {
        this.$parent.deliveryEditing=false;
      }
			this.closeModal()
		},
		closeModal: function () {
			this.$store.dispatch('changeSelectRespartnerDelivery', false);
			document.querySelector('html').style.overflow = null
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-mask {
	@include flex(center, center);
	@include position(0, 0, $position: fixed);
	z-index: 997;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.75);
	transition: opacity 0.3s ease;
	overflow: auto;
	padding: 5vh 0;
	scrollbar-color: $t $t;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		height: 0;
		width: 0;
	}

}

.modal {
	@include flex(center, center, column, $gap: 10px);
	background-color: $White;
	border-radius: 15px;
	width: 700px;
	max-width: 90%;
	box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 30%);
	padding: 20px;
	pointer-events: all;
	position: relative;
	z-index: 998;

	&__header {
		@include flex(flex-start, flex-start, $gap: 50px);
		width: 100%;

		button.close-modal {
			padding: 5px;
			aspect-ratio: 1;
			margin-left: auto;
		}
	}

	&__body {
		@include flex(flex-end, center, column, $gap: 10px);
		width: 100%;

		h2 {
			font-size: 18px;
			font-weight: 600;
			width: 100%;
			margin-bottom: 10px;
		}

		ul {
			@include flex(flex-start, flex-start, column, $gap: 15px);
			margin: 30px 0;

			li {
				label {
					@include flex(flex-start, center, $gap: 5px);
					cursor: pointer;

					span {
						transition: .3s;
					}

					&:hover input:not(:checked) ~ span {
						opacity: .5;
					}
				}
			}
		}

		.button {
			width: 100%;
			padding: 5px 10px;
			margin: 0 auto;
			@media screen and (min-width: 568px) {
				width: auto;
			}
		}
	}
}

</style>